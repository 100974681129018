import React, { useEffect, useState } from "react";
import { NavLink, Link, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as UserIcons } from "./Icons/UserIcons.svg";
import { ReactComponent as DashboardIcon } from "./Icons/DashboardIcon.svg";
import { ReactComponent as Agenda } from "./Icons/Agenda.svg";
import { ReactComponent as Messagerie } from "./Icons/Messagerie.svg";
import { ReactComponent as AttenteIcon } from "./Icons/AttenteIcon.svg";
import { ReactComponent as Annoncespublies } from "./Icons/annoncespublies.svg";
import { ReactComponent as Devis } from "./Icons/Devis.svg";
import { ReactComponent as FactureIcon } from "./Icons/FactureIcon.svg";
import { ReactComponent as Parametres } from "./Icons/Parametres.svg";
import Avatar from "@mui/material/Avatar";

export default function SideBar() {
  const MenuItem = [
    {
      Name: "Tableau de bord",
      Icon: <DashboardIcon />,
      Link: "/dashboard",
    },
    {
      Name: "Agenda",
      Icon: <Agenda />,
      Link: "/prouser/agenda",
    },
    {
      Name: "Messagerie",
      Icon: <Messagerie />,
      Link: "/prouser/messagerie",
    },
    {
      Name: "Annonces publiées",
      Icon: <Annoncespublies />,
      Link: "/prouser/annoncespublies",
    },
    {
      Name: "Annonces en attente",
      Icon: <AttenteIcon />,
      Link: "/prouser/annoncesenattente",
    },
    {
      Name: "Devis",
      Icon: <Devis />,
      Link: "/prouser/devis",
    },
    {
      Name: "Factures",
      Icon: <FactureIcon />,
      Link: "/prouser/factures",
    },
    {
      Name: "Paramètres entreprise",
      Icon: <Parametres />,
      Link: "/prouser/parametresentreprise",
    },
  ];

  const currentUserData = useSelector((state) => state.authentication.user);

  // Use the useState hook to manage the local state
  const [currentUser, setCurrentUser] = useState();

  // Use the useEffect hook to update the local state when the component mounts
  useEffect(() => {
    // Check if currentUserData is not null or undefined before updating the state
    if (currentUserData) {
      setCurrentUser(currentUserData?.data || currentUserData);
    }
  }, []); // Add currentUserData as a dependency to rerun the effect when it changes

  const [menuOpen, hendlemenuOpen, setMenuOpen] = useOutletContext();
  return (

    <>
      {/* <div className="xl:max-w-[327px] max-w-full w-full xl:max-h-[760px] h-full xl:pb-14 bg-white rounded-[20px] sideShadow"> */} 

      <div className={`xl:max-w-[327px] max-w-full xl:relative   w-full xl:max-h-[760px] h-full xl:pb-14 bg-white rounded-[20px] sideShadow   ${menuOpen ? "transition ease-in-out delay-1500  absolute left-0 right-0 top-0 xl:z-[9] z-[999] xl-h-full h-[100vh]" : "xl:block hidden transition ease-in-out delay-1500  "}`} > 
     
        <div className="flex items-center xl:justify-center justify-between px-4 py-3">
          <Link to={"/prouser/profile"}>

            <div className="flex  xl:flex-col flex-row justify-center items-center gap-2 xl:pt-4 pt-0">
              {/* <UserIcons /> `${process.env.REACT_APP_API_URL}uploads/${currentUserData?.UserProDetails?.details?.profile_image}` */}

              {currentUserData &&
                currentUserData.data &&
                currentUserData.data.profile_image ? (
                <img
                src={currentUserData?.data?.profile_image}
                
                  alt={currentUserData?.data?.profile_image}
                  className="w-[40px] h-[40px] xl:w-[100px] xl:h-[100px]"
                />
              ) : (
                <UserIcons />
              )}
              
              {/* {currentUserData &&
                        currentUserData.UserProDetails &&
                        currentUserData.UserProDetails.details &&
                        currentUserData.UserProDetails.details.profile_image ? (
                        <Avatar
                          alt={
                            currentUserData?.UserProDetails?.details
                              ?.profile_image
                          }
                          // sx={{ width: 33, height: 33 }}
                          src={((currentUserData?.UserProDetails?.details
                            ?.profile_image).startsWith('http://') || (currentUserData?.UserProDetails?.details
                            ?.profile_image).startsWith('https://'))?currentUserData?.UserProDetails?.details
                            ?.profile_image: process.env.REACT_APP_API_URL +
                            "uploads/" + currentUserData?.UserProDetails?.details?.profile_image
                          }
                        />
                      ) : (
                        <UserIcons />
                      )} */}

                <h3 className="text-base text-primary font-semibold">
                  {currentUserData &&
                    currentUserData.UserProDetails &&
                    currentUserData.UserProDetails.society
                    ? currentUserData.UserProDetails.society.company_name
                    : " "}
                </h3>
            </div>
          </Link>

        </div>

        <div className={`xl:mt-2 mt-3 `}>
       
          <ul>
            {MenuItem.map((items, index) => {
              return (
                <NavLink
                  key={index}
                  to={items.Link}
                  className="mainLi flex items-center gap-5 my-2 py-4 mr-4 rounded-e-full pl-7 text-black-light hover:text-white"
                  // state={{ userDt: userDt }}
                  onClick={()=>setMenuOpen(false)}
                >
                  {items.Icon}
                  <span className="text-base font-medium" >{items.Name}</span>
                </NavLink>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
