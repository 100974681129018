import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import frLocale from '@fullcalendar/core/locales/fr'; // Import French locale
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import moment from 'moment-timezone';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from './Layout';
import SearchHead from './SearchHead';
import Modal from './Model/Model';
import PageLoaderComp from '../component/pageLoaderComp';
import axios from 'axios';

const Agenda = () => {
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  // const handleDayClick = (info) => {
  //   const clickedDateString = moment(info.date).format('YYYY-MM-DD');
  //   setSelectedDate(clickedDateString);
  //   setIsModalOpen(true);
  // };

  const handleDayClick = (info) => {
    const clickedDate = moment(info.date);
    const currentDate = moment().startOf('day'); // Consider only the date part, ignore time
  
    if (clickedDate.isBefore(currentDate)) {
      console.log('Clicked date is in the past.'); // Optionally handle or notify the user
      return; // Exit the function without opening the modal
    }
  
    const clickedDateString = clickedDate.format('YYYY-MM-DDTHH:mm');
    setSelectedDate(clickedDateString);
    setIsModalOpen(true);
  };
  

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAddEvent = async(eventData) => {

    try {
      /* const requestData = {
        title: eventData.title,
        start: selectedDate,
        end: eventData.endDate,
      };
      let post_url = `${process.env.REACT_APP_API_URL}api/agenda`;

      // Use Axios to make the API request
      const response = await axios.post(post_url, requestData, {
          headers: {
              'Content-Type': 'application/json',
          },
      }); */
      const newEvent = {
        id: events.length + 1,
        title: eventData.title,
        start: selectedDate,
        end: eventData.endDate,
      };
      setEvents([...events, newEvent]);
      handleModalClose();

    } catch (error) {
        // Handle errors here
        console.error('Error in forgotPassword:', error.response.data);
        throw error.response.data.msg; // Rethrow the error to be caught by the calling code
    }
    
  };
  console.log("events",events);
  const currentUser = useSelector(state => state.authentication.user);
  if (!currentUser) {
    return <Navigate to="/" />;
  }
  console.log("selectedDate",selectedDate)
  const handleSearch = () => {

  }

  return (
    <>{pageLoading ?
      <PageLoaderComp />
      : ""}
      <Layout>
        <div className="mb-8 col-span-12 xl:col-span-9">
          <div className="xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]">
            <div className="mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between">
              <div>
                <h1 className="text-2xl 2xl:text-[25px] font-black text-white sm:mt-0 mt-4">Agenda</h1>
              </div>
              <div className="w-full sm:w-auto flex justify-end">
                {/* <SearchHead searchQuery={''} setSearchQuery={handleSearch} onSearch={() => { }} /> */}
              </div>
            </div>

            <div className="agenda-container">
              <FullCalendar
                locale={frLocale}  // Set the locale to French
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                initialView="dayGridMonth"
                events={events}
                dateClick={handleDayClick}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                }}
                buttonText={{
                  today: 'Aujourd\'hui',
                  month: 'Mois',
                  week: 'Semaine',
                  day: 'Jour',
                }}
              />
            </div>
          </div>
        </div>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            onAddEvent={handleAddEvent}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        )}
      </Layout>
    </>
  );
};

export default Agenda;
