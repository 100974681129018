import React, { useState, useEffect, useRef } from 'react'
import Layout from './Layout'
import './Style.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Link, Navigate } from 'react-router-dom';
import SearchHead from './SearchHead';
import { useSelector, useDispatch } from 'react-redux';
import { orderActions } from '../_actions';
import { changeDateFormatFrench, convertNumberFrench } from '../_helpers';
import { HiOutlineDownload } from 'react-icons/hi'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import moment from 'moment';
import PageLoaderComp from '../component/pageLoaderComp';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};
export default function AnnoncesPubliees() {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.authentication.user);
    const [pageLoading, setPageLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');

    const TabHead = [
        'Nom du client',
        'Formulaire',
        'Journal de diffusion',
        'Montant',
        'Date de création',
        'Facture',
        'Attestation',
    ]

    const [tabData, setTabData] = useState([]);
    const [totalRows, setTotalRows] = useState([]);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tabData.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const userData = (currentUser) => {
        // If user.data exists, use it; otherwise, use user directly
        return currentUser && currentUser.data ? currentUser.data : currentUser;
    };
    const user = userData(currentUser);
    const getAllDevise = () => {
        setPageLoading(true)
        dispatch(orderActions.getAll(user?.id, "devis")).then((data) => {
            setPageLoading(false)
            setTabData(data);
            setTotalRows(data);
        })
            .catch(() => {
                setPageLoading(false)
            });

    }

    const getAllDeviseRef = useRef(getAllDevise);
    useEffect(() => {
        window.scrollTo(0, 0)
        getAllDeviseRef.current();
    }, []);

    if (!currentUser) {
        return <Navigate to="/" />;
    }

/* 
    const filteredRows = tabData.filter((row) => {
        return Object.values(row).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    }); */

    return (
        <>
        {pageLoading ?
        <PageLoaderComp />
        : ""}
        <Layout>
            <div className='mb-8 col-span-12 xl:col-span-9'>
                <div className='max-w-[1126px] w-full h-full bg-white rounded-[20px]'>
                    <div className='mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between'>
                        <div>
                            <h1 className='text-2xl 2xl:text-[25px] font-black text-white sm:mt-0 mt-4'>Annonces publiées</h1>
                        </div>
                        <div className='w-full sm:w-auto flex justify-end'>
                            <SearchHead setTotalRows={setTotalRows} totalRows={totalRows} setTabData={setTabData} />
                        </div>
                    </div>

                    <div className='mt-8 sm:px-9 px-4 hidden lg:block'>
                        <TableContainer component={Paper} sx={{ boxShadow: 'none' }} >
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                                <TableHead>
                                    <TableRow >
                                        {
                                            TabHead.map((thItem, i) => {
                                                return (
                                                    <TableCell key={i} className='text-base font-normal text-black-light whitespace-nowrap'>{thItem}</TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {(rowsPerPage > 0
                                        ? tabData.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                        )
                                        : tabData
                                    ).map((tdItem, i) => {
                                            return (

                                                <TableRow key={i} >

                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{tdItem.username}</TableCell>
                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                                        {tdItem.form_type}
                                                    </TableCell>
                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{tdItem.journal_detail}</TableCell>
                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{convertNumberFrench(tdItem.total)} €</TableCell>
                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{moment(tdItem.creation_date).format("DD/MM/YYYY")}</TableCell>

                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                                        <Link target={"_blank"} alt={tdItem.votre_pdf} to={process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE + 'assets/uploads/pdfgenerate/invoice/' + tdItem.votre_pdf}>
                                                            <HiOutlineDownload className='w-5 h-5' />
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                                        <Link target={"_blank"} alt={tdItem.attestation} to={process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE + 'assets/uploads/pdfgenerate/attestation/' + tdItem.attestation}>
                                                            <HiOutlineDownload className='w-5 h-5' />
                                                        </Link>
                                                    </TableCell>

                                                </TableRow>

                                            )
                                        })
                                    }
                                    {tabData.length <= 0 && (
                                        <TableRow /* style={{ height: 53 * emptyRows }} */>
                                            <TableCell colSpan={6} ><p className='text-center font-bold capitalize text-lg'>Aucune donnée!</p></TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                            count={tabData.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </div>

                    <div className="mt-8  block lg:hidden ">

                    {(rowsPerPage > 0
                        ? tabData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                        )
                        : tabData
                    ).map((tdItem, i) => {
                        return (
                            <div className="bg-white rounded-[20px] shadow-md mx-2 p-3 mt-3 border border-[#eeeeee] ">
                                <div className="flex  gap-5 py-3">
                                    <div className="flex justify-between flex-col gap-2">
                                        {
                                            TabHead.map((thItem, i) => {
                                                return (
                                                    <Box key={i} className='text-base font-normal text-black-light border-0 whitespace-nowrap'>{thItem}</Box>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="flex justify-between flex-col gap-2">
                                        <div>{tdItem.username}</div>
                                        <div>{tdItem.form_type}</div>
                                        <div>{tdItem.journal_detail}</div>
                                        <div>{convertNumberFrench(tdItem.total)} €</div>
                                        <div>{moment(tdItem.creation_date).format("DD/MM/YYYY")}</div>
                                        <div> <Link target={"_blank"} alt={tdItem.votre_pdf} to={process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE + 'assets/uploads/pdfgenerate/invoice/' + tdItem.votre_pdf}>
                                            <HiOutlineDownload className='w-5 h-5' />
                                        </Link></div>
                                        <div>
                                            <Link target={"_blank"} alt={tdItem.attestation} to={process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE + 'assets/uploads/pdfgenerate/attestation/' + tdItem.attestation}>
                                                <HiOutlineDownload className='w-5 h-5' />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                count={tabData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>

                </div>
            </div>
        </div>
        </Layout >
        </>
    )
}

