import React,{useState,useEffect,useCallback,useRef} from 'react';
import { TextField, Box, FormControl, Select, MenuItem, OutlinedInput, InputAdornment, cardMediaClasses } from '@mui/material'
import { Link } from 'react-router-dom'
import ToastMessageComp from '../component/ToastMessageComp';
import PageLoaderComp from '../component/pageLoaderComp';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions,userActions } from '../_actions';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { alertConstants, userConstants } from '../_constants';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Paper, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const StyledDropZone = styled(Paper)(({ theme, dragging }) => ({
    padding: theme.spacing(2),
    width: '100%', // Full width
    marginRight: theme.spacing(3),
    border: '2px dashed grey',
    backgroundColor: dragging ? '#e3f2fd' : '#fafafa',
    color: 'grey',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#eceff1'
    },
  }));
export default function MesDocuments() {

    const alert = useSelector(state => state.alert);
    const currentUser = useSelector(state => state.authentication.user);
    const {UserBusinessDetails} = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [successfull, setSuccessfull] = useState(false);

    const [formData, setFormData] = useState({
        "user_id":currentUser && currentUser.data?currentUser.data.id:"",
        "type":"company",
        "companyArr":{
            "type":"company",
            "entreprise_name":"",
            "adresse":"",
            "statut_juridique":"",
            "userName":"",
            "siret":"",
            "intracommunity_vat":"",
            "naf_code":"",
            "vat":"",
            "created_at":"",
            "updated_at":"",
          
        },
        "documentsArr":{
            "type":"documents",
            "front_id":"",
            "front_id_old":currentUser && currentUser.UserBusinessDetails?currentUser.UserBusinessDetails.documentsArr.front_id_old:"",
            "front_id_url":currentUser && currentUser.UserBusinessDetails?currentUser.UserBusinessDetails.documentsArr.front_id_url:"",
            "back_id":"",
            "back_id_old":currentUser && currentUser.UserBusinessDetails?currentUser.UserBusinessDetails.documentsArr.back_id_old:"",
            "back_id_url":currentUser && currentUser.UserBusinessDetails?currentUser.UserBusinessDetails.documentsArr.back_id_url:"",
            "vigilance_certificate":"",
            "vigilance_certificate_old":currentUser && currentUser.UserBusinessDetails?currentUser.UserBusinessDetails.documentsArr.vigilance_certificate_old:"",
            "vigilance_certificate_url":currentUser && currentUser.UserBusinessDetails?currentUser.UserBusinessDetails.documentsArr.vigilance_certificate_url:"",
            "sirene_directory":"",            
            "sirene_directory_old":currentUser && currentUser.UserBusinessDetails?currentUser.UserBusinessDetails.documentsArr.sirene_directory_old:"",            
            "sirene_directory_url":currentUser && currentUser.UserBusinessDetails?currentUser.UserBusinessDetails.documentsArr.sirene_directory_url:"",            
            "created_at":"",
            "updated_at":"",
        },
        "paymentsArr":{
            "type":"payments",
            "society_name":"",
            "adresse_bank_acc_holder":"",
            "iban":"",
            "bic":"",
            "created_at":"",
        }
       
    });
    useEffect(() => {
        window.scrollTo(0, 0)        
        if(currentUser && currentUser.data && UserBusinessDetails){
            setFormData({
                "user_id":currentUser.data.id,
                "type":"documents",
                "companyArr":UserBusinessDetails.companyArr,
                "documentsArr":UserBusinessDetails.documentsArr,
                "paymentsArr":UserBusinessDetails.paymentsArr
            });
        }   
    }, [])
    const handleInputChange =async (e) => {
        // console.log("e",e)
        let {name, value} = e.target;
        // setIsLoading(true)
        setFormData(prevState => ({
            ...prevState,
            documentsArr: {
                ...prevState.documentsArr,
                [name]: value
            }
        }));
    };
    const handleSubmit = async(e) => {
        // handleClose()
        setSubmitted(true);
        
       
        // console.log("formdata",formData)
        /* if(!formData.documentsArr.front_id && !formData.documentsArr.back_id  && !formData.documentsArr.vigilance_certificate  && !formData.documentsArr.sirene_directory ){
           
            setSuccessfull(true);
            dispatch(alertActions.error("Veuillez télécharger des documents!"));
            setTimeout(() => {
                setSuccessfull(false)
                setSubmitted(false);
            }, 1000);
           
           return false;
        } */
        
        const formDataSend = new FormData();       
        Object.keys(formData).forEach(fieldName => { 
            formDataSend.append(fieldName, formData[fieldName]);   
        });
        Object.keys(formData.documentsArr).forEach(fieldName => {   
            if(fieldName !== "type"){
                formDataSend.append(fieldName, formData.documentsArr[fieldName]); 
            }
              
        });

        setIsLoading(true);    
        const response = await axios.post(`${process.env.REACT_APP_API_URL}api/prouser/user-business-settings`, formDataSend, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }); 
        if(response.data.success){
            setSuccessfull(true);
            dispatch(alertActions.success(response.data.msg));
            let UserBusinessDetails = response.data.UserBusinessDetails;
            dispatch({ type: userConstants.USER_BUSINESS_SETTINGS, UserBusinessDetails });
            if(localStorage.getItem('user_pro')!=null){
                let getLocalUser = JSON.parse(localStorage.getItem('user_pro'));
                getLocalUser.UserBusinessDetails = UserBusinessDetails;
                localStorage.setItem('user_pro', JSON.stringify(getLocalUser));
            }
        }else {
            setSuccessfull(true);
            dispatch(alertActions.error(response.data.msg));
            // navigate(-1)       
        }
        
        setTimeout(()=>{
            setIsLoading(false);
            setSubmitted(false);
            // setOpen(false);
        },1000)     
       /* 
        fetch(process.env.REACT_APP_API_URL+`api/prouser/user-business-settings`, {
            method: "POST",
            headers: { 'Content-Type': 'multipart/form-data' },
            // body: JSON.stringify(formData),
            body:formDataSend
        })
        .then((res) => res.json())
        .then((response) => {
            if(response.success){
                dispatch(alertActions.success(response.msg));
                let UserBusinessDetails = response.UserBusinessDetails;
                dispatch({ type: userConstants.USER_BUSINESS_SETTINGS, UserBusinessDetails });
                if(localStorage.getItem('user_pro')!=null){
                    let getLocalUser = JSON.parse(localStorage.getItem('user_pro'));
                    getLocalUser.UserBusinessDetails = UserBusinessDetails;
                    localStorage.setItem('user_pro', JSON.stringify(getLocalUser));
                }
            }else {
                dispatch(alertActions.error(response.msg));
                // navigate(-1)       
            }
            setTimeout(()=>{
                setIsLoading(false);
                setSubmitted(false);
                // setOpen(false);
            },1000)
        }); */
    };

    const [dragging, setDragging] = useState(false);
    const fileInputRef_front_id = useRef(null);
    const fileInputRef_back_id = useRef(null);
    const fileInputRef_vigilance_certificate = useRef(null);
    const fileInputRef_sirene_directory = useRef(null);

    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
      }, []);
    
      const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
      }, []);
    
      const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
      }, []);
    
      const handleDrop = useCallback((e,name) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        let url_name = "";
        if(name === "front_id"){
          url_name = "front_id_url";
        }
        if(name === "back_id"){
          url_name = "back_id_url";
        }
        if(name === "vigilance_certificate"){
          url_name = "vigilance_certificate_url";
        }
        if(name === "sirene_directory"){
          url_name = "sirene_directory_url";
        }
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFiles(e.dataTransfer.files,url_name);
            setFormData(prevState => ({
                ...prevState,
                documentsArr: {
                    ...prevState.documentsArr,
                    [name]: e.dataTransfer.files[0]
                }
            }));
          e.dataTransfer.clearData();
        }
      }, []);
    
      const handleClick = (e,name) => {
        if(name === "front_id"){
            fileInputRef_front_id.current.click();
        }
        if(name === "back_id"){
            fileInputRef_back_id.current.click();
        }
        if(name === "vigilance_certificate"){
            fileInputRef_vigilance_certificate.current.click();
        }
        if(name === "sirene_directory"){
            fileInputRef_sirene_directory.current.click();
        }
        
      };
    
      const handleFiles = (files,name) => {
        // console.log(files); // Process files here (e.g., uploading to a server)
        const file = files[0]; // Get the file from the input
            if (file) {
            const reader = new FileReader(); // Initialize FileReader

            reader.onload = (event) => {
                if(name){
                    setFormData(prevState => ({
                        ...prevState,
                        documentsArr: {
                            ...prevState.documentsArr,
                            [name]: event.target.result
                        }
                    }));
                }
                
            };

            reader.readAsDataURL(file); // Read the file as a data URL
            }
      };
    
      const handleChange = (event,name) => {

          let url_name = "";
          if(name === "front_id"){
            url_name = "front_id_url";
          }
          if(name === "back_id"){
            url_name = "back_id_url";
          }
          if(name === "vigilance_certificate"){
            url_name = "vigilance_certificate_url";
          }
          if(name === "sirene_directory"){
            url_name = "sirene_directory_url";
          }
        if (event.target.files && event.target.files.length > 0) {
            handleFiles(event.target.files,url_name);
            setFormData(prevState => ({
                ...prevState,
                documentsArr: {
                    ...prevState.documentsArr,
                    [name]: event.target.files[0]
                }
            }));
        }
    };
    const handleDownload = (e,imageUrl,image_name) => {
        const link = document.createElement('a');
        link.target = "_blank";
        link.href = imageUrl;
        link.download = image_name;  // This is optional but useful for setting a default download name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <>
            {/*  {isLoading?
            <PageLoaderComp />
            :""} */}
            
            {successfull?
             <ToastMessageComp  message={alert.message} type={alert.type} />
            :""}
            <h6 className='text-[22px] font-semibold text-[#414141] mt-8'>Pièce d’identité</h6>
            <p>Conformément à la législation européenne LCB-FT( lutte contre le blanchiment d'argent et le financement du terrorisme), nous devons vérifier l'identité du représentant légal de votre entreprise, qui est habilité à émettre des factures . Vos informations personnelles : sont strictement confidentielles et ne seront pas partagées.</p>

            <Box component="form" className='mt-3 sm:mt-6 3xl:mt-6'
                sx={{
                    "& .MuiInputLabel-root": { color: '#797979', },
                    "& fieldset": {
                        border: '1px solid #eee', borderRadius: '5px',
                        color: '#797979',
                        background: 'rgb(39 41 91 / 6%)',
                    },
                }}
            >
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Recto</label>
                    <div className='col-span-12 flex flex-col sm:flex-row sm:gap-0 gap-1'>
                        {/* <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            className='w-full outline-secondary focus:outline-secondary'
                            name='front'
                            value={formData.documentsArr.front}                            
                            onChange={handleInputChange}
                            error={submitted && !formData.documentsArr.front}
                            helperText={submitted && !formData.documentsArr.front?`Le nom de l'entreprise ne doit pas être vide`:""}
                        /> */}
                        
                        
                        <StyledDropZone
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={(e)=>handleDrop(e,"front_id")}
                            elevation={dragging ? 3 : 1}
                            onClick={(e)=>handleClick(e,"front_id")}
                            dragging={dragging}
                            className='p-2  w-full mr-3'
                           
                            >
                            <input
                                type="file"
                                name='front_id'
                                accept='image/*'
                                ref={fileInputRef_front_id}
                                onChange={(e)=>handleChange(e,"front_id")}
                                style={{ display: 'none' }}
                                // multiple  // Remove if only single file upload is required
                            />
                            <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{
                                mr: 2,
                                width: 30,
                                height: 30,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #ccc',
                                borderRadius: 1,
                                }}>
                                <IconButton>
                                    <AddIcon />
                                </IconButton>
                                </Box>
                                Glissez-déposez votre fichier ici
                            </Typography>
                        </StyledDropZone>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            // startIcon={<CloudUploadIcon />}
                            sx={{
                                backgroundColor:"#27295b",
                                '&:hover': {
                                    backgroundColor: '#27295b',
                                    color:"#fff"
                                },
                            }}
                            className='capitalize'
                            onClick={(e)=>{handleDownload(e,formData.documentsArr.front_id_url,formData.documentsArr.front_id_old)}}
                            >
                            Télécharger
                            {/* <VisuallyHiddenInput type="file" /> */}
                        </Button>
                    </div>
                    <div className={"mt-3  w-[200px]"}>
                        {formData.documentsArr.front_id_url && <img src={formData.documentsArr.front_id_url} alt="Uploaded Preview" className={"w-full"}  />}
                    </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Verso</label>
                    <div className='col-span-12 flex flex-col sm:flex-row sm:gap-0 gap-1'>
                        {/* <TextField
                            hiddenLabel
                            id="outlined-size-small"

                            className='w-full outline-secondary focus:outline-secondary'
                            name='adresse'
                            value={formData.documentsArr.adresse}
                            onChange={handleInputChange}
                            error={submitted && !formData.documentsArr.adresse}
                            helperText={submitted && !formData.documentsArr.adresse?`L'adresse ne doit pas être vide`:""}
                        /> */}
                        <StyledDropZone
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={(e)=>handleDrop(e,"back_id")}
                            elevation={dragging ? 3 : 1}
                            onClick={(e)=>handleClick(e,"back_id")}
                            dragging={dragging}
                            className='p-2  w-full mr-3'
                            >
                            <input
                                accept='image/*'
                                type="file"
                                name='back_id'
                                ref={fileInputRef_back_id}
                                onChange={(e)=>handleChange(e,"back_id")}
                                style={{ display: 'none' }}
                                // multiple  // Remove if only single file upload is required
                            />
                            <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{
                                mr: 2,
                                width: 30,
                                height: 30,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #ccc',
                                borderRadius: 1,
                                }}>
                                <IconButton>
                                    <AddIcon />
                                </IconButton>
                                </Box>
                                Glissez-déposez votre fichier ici
                            </Typography>
                        </StyledDropZone>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            // startIcon={<CloudUploadIcon />}
                            sx={{
                                backgroundColor:"#27295b",
                                '&:hover': {
                                    backgroundColor: '#27295b',
                                    color:"#fff"
                                },
                            }}
                            className='capitalize'
                            onClick={(e)=>{handleDownload(e,formData.documentsArr.back_id_url,formData.documentsArr.back_id_old)}}
                            >
                            Télécharger
                            {/* <VisuallyHiddenInput type="file" /> */}
                        </Button>
                    </div>
                    <div className={"mt-3  w-[200px]"}>
                        {formData.documentsArr.back_id_url && <img src={formData.documentsArr.back_id_url} alt="Uploaded Preview" className={"w-full"}  />}
                    </div>
                </div>
                {/* <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Statut juridique</label>
                    <div className='col-span-12'>
                        <FormControl fullWidth>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name='statut_juridique'
                                value={formData.documentsArr.statut_juridique}
                                onChange={handleInputChange}
                                error={submitted && !formData.documentsArr.statut_juridique}
                                helperText={submitted && !formData.documentsArr.statut_juridique?`le statut juridique ne doit pas être vide`:""}
                            >
                                <MenuItem value={"SARL"}>SARL</MenuItem>
                                <MenuItem value={"SAS"}>SAS</MenuItem>
                                <MenuItem value={"HIS"}>HIS</MenuItem>
                                <MenuItem value={"E.I."}>E.I.</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div> */}
                <div className='mt-12'>
                    <h6 className='text-[22px] font-semibold text-black'>Attestation de vigilance</h6>
                    <p>L'attestation de vigilance est une attestation qui est délivrée par l'Urssaf.L'attestation confirme que votre situation concernant le paiement de vos cotisations sociales est en règle.</p>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    {/* <label className='col-span-12 text-base font-medium text-gray mb-1'>Nom d’usage</label> */}
                    <div className='col-span-12 flex flex-col sm:flex-row sm:gap-0 gap-1'>
                        {/* <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            className='w-full outline-secondary focus:outline-secondary'
                            name='userName'
                            value={formData.documentsArr.userName}
                            onChange={handleInputChange}
                            error={submitted && !formData.documentsArr.userName}
                            helperText={submitted && !formData.documentsArr.userName?`le nom d'utilisateur ne doit pas être vide`:""}
                        /> */}
                        <StyledDropZone
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={(e)=>handleDrop(e,"vigilance_certificate")}
                            elevation={dragging ? 3 : 1}
                            onClick={(e)=>handleClick(e,"vigilance_certificate")}
                            dragging={dragging}
                            className='p-2  w-full mr-3'
                            name='vigilance_certificate'
                            >
                            <input
                                type="file"
                                name='vigilance_certificate'
                                accept='image/*'
                                ref={fileInputRef_vigilance_certificate}
                                // onChange={handleChange}
                                onChange={(e)=>handleChange(e,"vigilance_certificate")}
                                style={{ display: 'none' }}
                                // multiple  // Remove if only single file upload is required
                            />
                            <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{
                                mr: 2,
                                width: 30,
                                height: 30,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #ccc',
                                borderRadius: 1,
                                }}>
                                <IconButton>
                                    <AddIcon />
                                </IconButton>
                                </Box>
                                Glissez-déposez votre fichier ici
                            </Typography>
                            </StyledDropZone>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                // startIcon={<CloudUploadIcon />}
                                sx={{
                                    backgroundColor:"#27295b",
                                    '&:hover': {
                                        backgroundColor: '#27295b',
                                        color:"#fff"
                                    },
                                }}
                                className='capitalize'
                                onClick={(e)=>{handleDownload(e,formData.documentsArr.vigilance_certificate_url,formData.documentsArr.vigilance_certificate_old)}}
                                >
                                Télécharger
                                {/* <VisuallyHiddenInput type="file" /> */}
                            </Button>
                    </div>
                    <div className={"mt-3  w-[200px]"}>
                        {formData.documentsArr.vigilance_certificate_url && <img src={formData.documentsArr.vigilance_certificate_url} alt="Uploaded Preview" className={"w-full"}  />}
                    </div>
                </div>
                {/* <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Siret</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            className='w-full outline-secondary focus:outline-secondary'
                            name='siret'
                            value={formData.documentsArr.siret}
                            onChange={handleInputChange}
                            error={submitted && !formData.documentsArr.siret}
                            helperText={submitted && !formData.documentsArr.siret?`siret ne doit pas être vide`:""}
                        />
                    </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>TVA intracommunautaire</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            className='w-full outline-secondary focus:outline-secondary'
                            name='intracommunity_vat'
                            value={formData.documentsArr.intracommunity_vat}
                            onChange={handleInputChange}
                            error={submitted && !formData.documentsArr.intracommunity_vat}
                            helperText={submitted && !formData.documentsArr.intracommunity_vat?`TVA intracommunautaire ne doit pas être vide`:""}
                        />
                    </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    <label className='col-span-12 text-base font-medium text-gray mb-1'>Code NAF</label>
                    <div className='col-span-12'>
                        <TextField
                            hiddenLabel
                            id="outlined-size-small"
                            className='w-full outline-secondary focus:outline-secondary'
                            name='naf_code'
                            value={formData.documentsArr.naf_code}
                            onChange={handleInputChange}
                            error={submitted && !formData.documentsArr.naf_code}
                            helperText={submitted && !formData.documentsArr.naf_code?`Le code NAF ne doit pas être vide`:""}
                        />
                    </div>
                </div> */}
                <div className='mt-12'>
                    <h6 className='text-[22px] font-semibold text-black'>Avis de situation au répertoire SIRENE</h6>
                    <p>L' avis de situation au répertoire Sirene est un document permettant de rechercher une entreprise ou un établissement au sein du répertoire d'identification des entreprises et de leurs établissements.
Pour obtenir l'avis de situation, vous devez vous munir du numéro Siren de l'entreprise ou du numéro Siret de l'établissement recherché.</p>
                </div>
                <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
                    {/* <label className='col-span-12 text-base font-medium text-gray mb-1'>TVA</label> */}
                    <div className='col-span-12 flex flex-col sm:flex-row sm:gap-0 gap-1'>
                        {/* <FormControl variant="outlined">
                            <OutlinedInput
                                id="TVA"
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                placeholder='0'
                                name='vat'
                                value={formData.documentsArr.vat}
                                onChange={handleInputChange}
                                error={submitted && !formData.documentsArr.vat}
                                helperText={submitted && !formData.documentsArr.vat?`TVA ne doit pas être vide`:""}
                            />
                        </FormControl> */}
                        <StyledDropZone
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={(e)=>handleDrop(e,"sirene_directory")}
                            elevation={dragging ? 3 : 1}
                            onClick={(e)=>handleClick(e,"sirene_directory")}
                            dragging={dragging}
                            className='p-2  w-full mr-3'
                            name='sirene_directory'
                            >
                            <input
                                type="file"
                                accept='image/*'
                                name='sirene_directory'
                                ref={fileInputRef_sirene_directory}
                                // onChange={handleChange}
                                onChange={(e)=>handleChange(e,"sirene_directory")}
                                style={{ display: 'none' }}
                                // multiple  // Remove if only single file upload is required
                            />
                            <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{
                                mr: 2,
                                width: 30,
                                height: 30,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #ccc',
                                borderRadius: 1,
                                }}>
                                <IconButton>
                                    <AddIcon />
                                </IconButton>
                                </Box>
                                Glissez-déposez votre fichier ici
                            </Typography>
                            </StyledDropZone>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                // startIcon={<CloudUploadIcon />}
                                sx={{
                                    backgroundColor:"#27295b",
                                    '&:hover': {
                                        backgroundColor: '#27295b',
                                        color:"#fff"
                                    },
                                }}
                                className='capitalize'
                                onClick={(e)=>{handleDownload(e,formData.documentsArr.sirene_directory_url,formData.documentsArr.sirene_directory_old)}}
                                >
                                Télécharger
                                {/* <VisuallyHiddenInput type="file" /> */}
                            </Button>
                    </div>
                    <div className={"mt-3 w-[200px]"}>
                        {formData.documentsArr.sirene_directory_url && <img src={formData.documentsArr.sirene_directory_url} alt="Uploaded Preview" className={"w-full"}  />}
                    </div>
                </div>
                {/* <Link to='/' type='submit' className='bg-primary text-base xl:text-xl 3xl:text-xl font-bold text-white  mt-4 sm:mt-4 3xl:mt-10 px-10   py-3   rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white '>Enregistrer</Link> */}
                <LoadingButton
                    className='bg-primary text-base xl:text-xl 3xl:text-xl font-bold text-white  mt-5 sm:mt-5 3xl:mt-10 px-10   py-3   rounded-lg transition-all duration-300 hover:bg-secondary hover:text-white capitalize'
                    size="small"
                    onClick={handleSubmit}
                    endIcon={<SendIcon />}
                    loading={isLoading}
                    loadingPosition="end"
                    variant="contained"                    >
                    <span>Enregistrer</span>
                </LoadingButton>
            </Box>
        </>
    )
}
